import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { APP_DOWNLOAD_URL, APP_PRO_URL } from './MealsSorted';
import DownloadIos from '../../components/svgs/DownloadIos';

import bowlsBerry from '../../components/svgs/bowls-icon-berry.svg';
import bowlsBlueberry from '../../components/svgs/bowls-icon-blueberry.svg';
import bowlsKiwi from '../../components/svgs/bowls-icon-kiwi.svg';
import bowlsMustard from '../../components/svgs/bowls-icon-mustard.svg';

import groceriesScreenshot from '../../images/groceries-screenshots.png';
import mealDetailsScreenshot from '../../images/meal-details-screenshots.png';
import mealsScreenshot from '../../images/meals-screenshot.png';
import weeklyPlannerScreenshot from '../../images/weekly-planner-screenshots.png';
import { Container } from '../../components/mealssorted/Container';

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0 4px;

  @media (min-width: 992px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5%;
    margin-bottom: 120px;
    padding: 0;
  }

  @media (min-width: 1180px) {
    gap: 6%;
  }
`;

const Intro = styled.div`
  margin-bottom: 40px;

  @media (min-width: 992px) {
    flex: 0 0 42%;
    margin-bottom: 0;
    margin-left: 4%;
  }

  @media (min-width: 1180px) {
    margin-left: 6%;
  }
`;

const Heading = styled.h1`
  font-family: 'ClashDisplay-Bold', sans-serif;
  font-size: 48px;
  line-height: 1.1;
  margin-bottom: 25px;
  margin-top: 0;

  @media (min-width: 992px) {
    font-size: 72px;
    white-space: pre;
  }
`;

const IntroText = styled.p`
  color: ${props => props.theme.liquorice};
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 35px;

  @media (min-width: 992px) {
    font-size: 22px;
  }
`;

const ImageWrapper = styled.div<{ $backgroundImg: string; }>`
  background-image: url(${(props) => props.$backgroundImg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 992px) {
    flex: 1 1 auto;
    margin-right: 2%;
  }

  @media (min-width: 1180px) {
    margin-right: 6%;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const BlockSection = styled.section<{ $reverseMobile?: boolean; }>`
  display: flex;
  flex-direction: ${(props) => props.$reverseMobile ? 'column-reverse' : 'column'};
  margin-bottom: 50px;
  padding: 0 4px;

  @media (min-width: 992px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16.6666667%;
    margin-bottom: 120px;
    padding: 0;
  }

  @media (min-width: 1180px) {
    gap: 9%;
  }
`;

const BlockContent = styled.div<{ $align: string; }>`
  @media (min-width: 992px) {
    flex: 1 1 auto;
    margin-right: ${(props) => props.$align === 'right' ? '4%' : 0};
    margin-left: ${(props) => props.$align === 'left' ? '4%' : 0};
  }

  @media (min-width: 1180px) {
    margin-right: ${(props) => props.$align === 'right' ? '13%' : 0};
    margin-left: ${(props) => props.$align === 'left' ? '13%' : 0};
  }
`;

const BlockImageWrapper = styled.div<{ $align: string; $backgroundImg: string; }>`
  background-image: url(${(props) => props.$backgroundImg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.$align === 'left' ? '40px' : '30px'};

  @media (min-width: 992px) {
    flex: 1 0 auto;
    margin-left: ${(props) => props.$align === 'left' ? '3%' : 0};
    margin-right: ${(props) => props.$align === 'right' ? '3%' : 0};
    margin-top: 0;
  }

  @media (min-width: 1180px) {
    margin-left: ${(props) => props.$align === 'left' ? '8%' : 0};
    margin-right: ${(props) => props.$align === 'right' ? '8%' : 0};
  }
`;

const BlockImage = styled.img`
  max-width: 100%;

  @media (min-width: 992px) {
    margin: 35px 65px;
  }
`;

const BlockTitle = styled.h2`
  font-family: 'ClashDisplay-Semibold', sans-serif;
  font-size: 40px;
  line-height: 1.2;
`;

const BlockText = styled.p`
  color: ${props => props.theme.liquorice};
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 35px;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

const PromoBlock = styled.section`
  background-color: ${(props) => props.theme.kiwi30};
  margin-top: 100px;
  padding: 105px 0 95px;

  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

const PromoContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PromoTitle = styled.h3`
  font-family: 'ClashDisplay-Semibold', sans-serif;
  font-size: 42px;
  line-height: 1.2;
  margin: 0 0 20px;
`;

const PromoText = styled.p`
  color: ${props => props.theme.liquorice};
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 30px;
  text-align: center;
`;

const PromoAction = styled.a`
  background: ${(props) => props.theme.kiwi};
  border: 1px solid ${(props) => props.theme.kiwi};
  border-radius: 5px;
  color: ${(props) => props.theme.white};
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 40px;
  text-decoration: none;

  &:hover {
    background: transparent;
    color: ${(props) => props.theme.kiwi};
  }
`;

const Home: React.FC = () => {
  return (
    <>
      <Container>
        <HeroSection>
          <Intro>
            <Heading>{'Your meals,\nsorted.'}</Heading>
            <IntroText>
              {`The Meals Sorted app saves you time and effort by helping you to plan your meals and sort your
              ingredients.`}
            </IntroText>
            {!APP_DOWNLOAD_URL && (
              <IntroText>Coming soon...</IntroText>
            )}
            {APP_DOWNLOAD_URL && (
              <Link to={APP_DOWNLOAD_URL}>
                <DownloadIos />
              </Link>
            )}
          </Intro>
          <ImageWrapper $backgroundImg={bowlsMustard}>
            <Image src={mealsScreenshot} />
          </ImageWrapper>
        </HeroSection>
        <BlockSection $reverseMobile={true}>
          <BlockImageWrapper $align="left" $backgroundImg={bowlsKiwi}>
            <BlockImage src={mealDetailsScreenshot} />
          </BlockImageWrapper>
          <BlockContent $align="right">
            <BlockTitle>{'Build your personal cookbook'}</BlockTitle>
            <BlockText>
              {`Add, edit, and organise your favourite recipes with ease. Categorise your meals with custom labels like
              "Quick", "Vegetarian", or "Kid-Friendly" to customise randomised weekly plans.`}
            </BlockText>
          </BlockContent>
        </BlockSection>
        <BlockSection>
          <BlockContent $align="left">
            <BlockTitle>{'Create randomised weekly meal plans'}</BlockTitle>
            <BlockText>
              {`Let Meals Sorted create randomised weekly meal plans based on your preferences. Randomise the selection to
              keep your plans fresh or pick and choose as you desire!`}
            </BlockText>
          </BlockContent>
          <BlockImageWrapper $align="right" $backgroundImg={bowlsBerry}>
            <BlockImage src={weeklyPlannerScreenshot} />
          </BlockImageWrapper>
        </BlockSection>
        <BlockSection $reverseMobile={true}>
          <BlockImageWrapper $align="left" $backgroundImg={bowlsBlueberry}>
            <BlockImage src={groceriesScreenshot} />
          </BlockImageWrapper>
          <BlockContent $align="right">
            <BlockTitle>{'Auto generate grocery lists'}</BlockTitle>
            <BlockText>
              {`Say goodbye to forgotten ingredients. Meals Sorted generates your weekly grocery list based on your meal
              plan with just one tap.`}
            </BlockText>
          </BlockContent>
        </BlockSection>
      </Container>
      {APP_PRO_URL && (
        <PromoBlock>
          <PromoContainer>
            <PromoTitle>{'Unlock features!'}</PromoTitle>
            <PromoText>
              {`Upgrade to Meals Sorted Pro to unlock the limits on features for a simple one-off payment.`}
            </PromoText>
            <PromoAction href={APP_PRO_URL} target="_blank">
              {'Upgrade to Pro'}
            </PromoAction>
          </PromoContainer>
        </PromoBlock>
      )}
    </>
  );
};

export default Home;
