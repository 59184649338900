import debounce from 'debounce';
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import styled, { createGlobalStyle } from 'styled-components';

import Menu from '../components/Menu';
import MobileHeader from '../components/MobileHeader';
import Text from '../components/common/Text';
import Profile from '../components/Profile';
import Section, { SectionIntro } from '../components/Section';
import Tile, { TileHeading } from '../components/Tile';

import timImg from '../images/tim.jpg';
import davidImg from '../images/david.jpg';
import { List, ListItem } from '../components/common/List';
import { NavLink } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #2D2D2D;
  }
`;

const Wrapper = styled.main`
  background-color: #2D2D2D;
  padding-bottom: 50px;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 992px) {
    padding-left: 98px;
  }
`;

const MainHeading = styled.h1`
  background-color: #D78AFB;
  background-image: linear-gradient(45deg, #D78AFB, #FFCC3E);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 38px;
  margin: 30px 0 64px;

  @media (min-width: 992px) {
    margin-top: 0;
    padding-top: 160px;
  }
`;

const LeadText = styled(Text)`
  color: #ECECEC;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const NavigationLink = styled(NavLink)`
  color: #ECECEC;
  text-decoration: none;

  &:hover {
    color: #55E4BA;
  }
`;

const Link = styled.a`
  color: #ECECEC;
  text-decoration: none;

  &:hover {
    color: #55E4BA;
  }
`;

const HomePage: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const checkMenuDisplay = debounce(() =>
      setMenuOpen(window.innerWidth >= 992),
      200
    );

    // initial check
    checkMenuDisplay();

    window.addEventListener('resize', checkMenuDisplay);

    return () => {
      window.removeEventListener('resize', checkMenuDisplay);
    }
  }, []);

  return (
    <Wrapper id="content">
      <GlobalStyle />
      <Element name="top"></Element>
      <Menu open={menuOpen} onClose={() => setMenuOpen(false)} />
      <Container>
        <MobileHeader onHamburgerClick={() => setMenuOpen(!menuOpen)} />
        <MainHeading>Web project delivery experts.</MainHeading>
        <Section anchor="about" name="Who we are">
          <SectionIntro>
            <LeadText>
              We're a team of developers with over 20 years combined experience,
              working with a range of clients from startups to SMEs, through to
              enterprise and government agencies.
            </LeadText>
          </SectionIntro>
          <Profile image={timImg} name="Tim Kung">
            <Text>
              I've been in the web development industry since 2012 and have
              worked on a variety of projects spanning a range of different
              technology stacks.
            </Text>
            <Text>
              I have a passion for building beautiful interfaces that provide a
              great user experience, as well as constantly learning about
              emerging tech and sharing knowledge with colleagues.
            </Text>
          </Profile>
          <Profile image={davidImg} name="David Craig">
            <Text>
              I've been building software professionally since 2009 across a
              range of technologies.
            </Text>
            <Text>
              As well as having a strong focus on tech, I enjoy the people side
              of software, working with clients to bring them along the journey.
            </Text>
          </Profile>
        </Section>
        <Section anchor="services" name="What we do">
          <SectionIntro>
            <LeadText>
              We partner with leading digital agencies to assist with project
              delivery.
            </LeadText>
            <LeadText>
              We can be embedded within an existing team or work as a self
              sufficient project delivery team. 
            </LeadText>
          </SectionIntro>
          <Tile direction="column">
            <TileHeading>Content Managed Websites</TileHeading>
            <Text>
              Specialising in Silverstripe CMS development, we've built websites
              for New Zealand Goverment agancies, retail banks, and SMEs.
            </Text>
          </Tile>
          <Tile direction="column">
            <TileHeading>Web Apps</TileHeading>
            <Text>
              With a strong background in JavaScript frameworks, we deliver API
              based applications, built to scale on cloud infrastructure.
            </Text>
          </Tile>
          <Tile direction="column">
            <TileHeading>Mobile Apps</TileHeading>
            <Text>
              We have developed a handful of mobile applications for clients
              over the years and will be releasing a range of our own to app
              stores near you!
            </Text>
          </Tile>
        </Section>
        <Section anchor="portfolio" name="Portfolio">
          <SectionIntro>
            <LeadText>
              Check out some of our work!
            </LeadText>
          </SectionIntro>
          <List>
            <ListItem>
              <NavigationLink to="meals-sorted">Meals Sorted</NavigationLink>
            </ListItem>
          </List>
        </Section>
        <Section anchor="contact" name="Contact us">
          <Text>
            <Link href="mailto:hello@thehiveteam.co.nz" target="_blank">
              hello@thehiveteam.co.nz
            </Link>
          </Text>
        </Section>
      </Container>
    </Wrapper>
  );
}

export default HomePage;
