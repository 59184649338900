import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from './Container';
import MealsSortedLogoBlack from '../svgs/MealsSortedLogoBlack';
import { RoutesEnum } from '../../App';
import DownloadIosOutline from '../svgs/DownloadIosOutline';
import { APP_DOWNLOAD_URL } from '../../pages/mealssorted/MealsSorted';

const FooterEl = styled.footer`
  background: ${props => props.theme.liquorice};
  padding: 76px 0;
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 675px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const NavItems = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const FooterLink = styled(NavLink)`
  color: ${props => props.theme.white};
  padding-left: 10px;
`;

const FooterExtLink = styled(Link)`
  color: ${props => props.theme.white};
`;

const Footer: React.FC = () => {
  return (
    <FooterEl>
      <FooterContainer>
        <NavItems>
          <Link to={`/${RoutesEnum.mealsSorted}`}>
            <MealsSortedLogoBlack />
          </Link>
          <FooterLink to={RoutesEnum.msPrivacyPolicy}>Privacy policy</FooterLink>
          <FooterExtLink to="/">Developed by Hive</FooterExtLink>
        </NavItems>
        {APP_DOWNLOAD_URL && (
          <Link to={APP_DOWNLOAD_URL}>
            <DownloadIosOutline />
          </Link>
        )}
      </FooterContainer>
    </FooterEl>
  );
};

export default Footer;
