import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import MealsSortedBowl from '../svgs/MealsSortedBowl';
import MealsSortedLogo from '../svgs/MealsSortedLogo';
import { APP_DOWNLOAD_URL, APP_PRO_URL } from '../../pages/mealssorted/MealsSorted';

const HeaderEl = styled.header`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 45px;
  padding: 20px 20px;
  width: 100%;

  @media (min-width: 992px) {
    margin-bottom: 85px;
    padding: 40px 50px;
  }
`;

const LogoLink = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 200px;

  @media (min-width: 768px) {
    max-width: none
  }
`;

const NavItems = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const NavItem = styled(Link)`
  display: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 675px) {
    color: ${props => props.theme.berry};
    display: block;
    font-family: 'ClashDisplay-Semibold', sans-serif;
    font-size: 14px;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const NavButton = styled(Link)`
  background: ${props => props.theme.berry};
  border: 1px solid ${props => props.theme.berry};
  border-radius: 5px;
  color: ${props => props.theme.white};
  font-family: 'ClashDisplay-Semibold', sans-serif;
  padding: 12px 20px;
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.white};
    color: ${props => props.theme.berry};
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderEl>
      <LogoLink to="/meals-sorted">
        <MealsSortedBowl />
        <MealsSortedLogo />
      </LogoLink>
      <NavItems>
        <NavItem to={`mailto:hello@thehiveteam.co.nz?subject=${encodeURI('Meals Sorted Feedback')}`}>Feedback</NavItem>
        {APP_PRO_URL && (
          <NavItem to={APP_PRO_URL} target="_blank">
            Upgrade to Pro
          </NavItem>
        )}
        {APP_DOWNLOAD_URL && <NavButton to={APP_DOWNLOAD_URL}>Download</NavButton>}
      </NavItems>
    </HeaderEl>
  );
};

export default Header;
