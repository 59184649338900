import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Footer from '../../components/mealssorted/Footer';
import Header from '../../components/mealssorted/Header';
import { Outlet } from 'react-router-dom';

export const APP_DOWNLOAD_URL = 'https://apps.apple.com/us/app/meals-sorted/id6670163311';
export const APP_PRO_URL = 'itms-services://?action=purchaseIntent&bundleId=nz.co.thehiveteam.MealsSorted&productIdentifier=pro_upgrade';

const theme = {
  berry: '#FF503C',
  kiwi30: '#D6EBC5',
  kiwi: '#76BC3D',
  liquorice: '#2C2429',
  white: '#FFFFFF',
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

const PageContainer = styled.div`
  flex-grow: 1;
`;

const MealsSorted: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Page>
        <Header />
        <PageContainer>
          <Outlet />
        </PageContainer>
        <Footer />
      </Page>
    </ThemeProvider>
  );
};

export default MealsSorted;
